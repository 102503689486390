import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.css';
import Speciality_queue from './components/SpecialtyQueue';
import QButton from './components/QButton';
import DqButton from './components/dQbutton';
import { GetIsTeacher, InitUserStateListener } from './auth/login';
import { auth, firestore_db } from './auth/firebaseConfig';
import MakeAuthenticatedRequest from './auth/token';
import Container from './components/Container';
import LogoutButton from './components/LogoutButton';
import { toast, Toaster } from 'react-hot-toast';
import { doc, getDoc, setDoc ,updateDoc} from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';
import StudentCardAttribute from './components/location_card';
import LoadingOverlay from './screens/LoadingOverlay';
import ProfessorOverlay from './screens/ProfessorOverlay';
import AskNameOverlay from './screens/askNameOverlay';

const formatName = (firstName, lastName) =>{
    let full = '';
    if (firstName != '') {
        full += firstName;
    }
    if (lastName != '') {
        if (full != '') full += ' ';
        full += lastName;
    }

    return full;
}

async function QueueMeInServer(setId, queue_id, location, user, name, profName)  {
    console.log("QueueMeInServer")

    if(!user) {
        console.error('Error: User not defined and QueueMeInServer called'); //TODO: handle error with pop-up
        return;
    }
    const token = await user.getIdToken();
    console.log("queueid in queuemeinserve, " , queue_id)

    MakeAuthenticatedRequest(`${queue_id}/enqueue`,token, { //TODO: pass the queue_id
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: { name: name, location: location, pref_prof: profName}, //TODO: pass in authenticated user name instead of email and then add the
    })
        .then(response => response.json())
        .then(data => {
            setId(data.id); // assuming the id is returned in the 'id' field
        });
}


async function LeaveQueueInServer(setId, queue_id, id, user) {
    console.log("LeaveQueueInServer")
    if(! user) {
        console.error('Error: User not defined and LeaveQueueInServer called'); //TODO: handle error with pop-up
        return;
    }
    const userToken = await user.getIdToken()
    MakeAuthenticatedRequest(`${queue_id}/remove/${id}`,userToken, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
          },
    })
        .then(response => response.json())
        .then(data => {
        console.log('Success:', data);
            setId(null);
        })
        .catch(error => {
            console.error('Error:', error); //TODO: handle error with pop-up
            return;
        });
}

//TODO: tricky situation for the token
// Teachers should have a token to remove anyone
function DQAsTeacher(queue_id) {
    console.log("DQAsTeacherServer")
    MakeAuthenticatedRequest(`${queue_id}/dequeue`, "TODO: token", { //TODO: pass the queue_id
        method: 'DELETE',
        headers: {
        'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then(data => {
        console.log('Success:', data);})
        .catch(error => {
            console.error('Error:', error); //TODO: handle error with pop-up
            return;
        });
}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

function App() {
    const [id, setId] = useState(null); //should be a map of (table id) to (id in table)
    const [user, setUser] = useState(null);
    const [isTeacher, setTeacher] = useState(true);
    const [currentHelpedStudent, setcurrentHelpedStudent] = useState({
        Name: '',
        Location: ''
      });
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isAskingProfPref, setIsAskingProfPref] = useState(false);
    const [isAskingDisplayName, setIsAskingDisplayName] = useState(false);

    const [profName, setProfName] = useState('');

    const setUserAndGetMetaData = async (user_obj)  =>{

        try {
            getDoc(doc(firestore_db, "users", user_obj.uid)).then(
                (docSnap) =>   {
                    if (docSnap.exists()) {
                        // console.log("Document data:", docSnap.data());
                        setTeacher(docSnap.data().isTeacher == "yes");
                        let data = docSnap.data()
                        setcurrentHelpedStudent({
                            Name:data.lastPickedStudentName,
                            Location: data.lastPickedStudentLocation
                        });
                        //if exist don't ask
                        if (data.firstName === '' && data.lastName === '') {
                            setIsAskingDisplayName(true);
                            console.log("(data.firstName == '' && data.lastName == '')");
                        } else {
                            setFirstName(data.firstName);
                            setLastName(data.lastName);
                        }
                        //Otherwise ask and update state

                    } else {

                        toast.error("Internal error. User metadata corrupted. Contact customer suppport!", {style: {
                            padding: '8px 12px',  // Smaller padding to reduce overall size
                            minWidth: '100px',  // Minimum width to maintain structure
                            maxWidth: '300px',  // Maximum width to ensure it doesn't get too large
                            fontSize: '13px',  // Maintain a smaller font size for better fitting
                            color: 'green',  // Optional, to keep the success indication clear
                            borderRadius: '10px'  } })

                    }
                 }
             )
             setUser(user_obj)
        } catch {
            //TODO: why does it hit the catch

        } finally {
            await sleep(500); // Sleep for 1 second
            setIsLoading(false);
        }

    }
    InitUserStateListener(setUserAndGetMetaData);


    const displayName = () => {
        let cardDisplayName = formatName(firstName, lastName) ;
        if (cardDisplayName == '' && user) {
            cardDisplayName = user.email;
        }
        return cardDisplayName;
    }

    const QMgrInServer = (queue_id, location, profName) => {
        let cardDisplayName = displayName();
        QueueMeInServer(setId,queue_id, location, user, cardDisplayName, profName);
    };
    const [location, setLocation] = useState('');
    const updateText = (event) => {
        setLocation(event.target.value);
    };
    const getLocation = () => {
        return location;
    }

    const [isFlashingQueues, setisFlashingQueues] = useState(false);
    const [isFlashingCards, setisFlashingCards] = useState(false);



    // const InitLastHelpedStudent = () => {

    //     const stopMe = onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             // Unsubscribe from further auth state changes
    //             stopMe();
    //             user.getIdToken(true) //force refresh token before sending email verification
    //             //This doesn't continue:
    //             getDoc(doc(firestore_db, "users", user.uid)).then(
    //                 (docSnap) =>  {
    //                  if (docSnap.exists()) {
    //                      // console.log("Document data:", docSnap.data());
    //                      setTeacher(docSnap.data().isTeacher == "yes");
    //                      let data = docSnap.data()
    //                      setcurrentHelpedStudent({
    //                         Name:data.lastPickedStudentName,
    //                         Location: data.lastPickedStudentLocation
    //                       });
    //                  } else {

    //                      toast.error("Internal error. User metadata corrupted. Contact customer suppport!", {style: {
    //                          padding: '8px 12px',  // Smaller padding to reduce overall size
    //                          minWidth: '100px',  // Minimum width to maintain structure
    //                          maxWidth: '300px',  // Maximum width to ensure it doesn't get too large
    //                          fontSize: '13px',  // Maintain a smaller font size for better fitting
    //                          color: 'green',  // Optional, to keep the success indication clear
    //                          borderRadius: '10px'  } })

    //                  }
    //                  }
    //              )

    //         }
    //     });


    // };




    // InitLastHelpedStudent();

    const toggleQueuesFlashing = () => {
      setisFlashingQueues(!isFlashingQueues);
    };
    const handleChooseStudent = (user_name, user_location) => {
        toast.dismiss(); // remove the 'click the queue you want to join'
        toast.success(`You can now start helping ${user_name} at ${user_location}`, {style: {
            padding: '8px 12px',  // Smaller padding to reduce overall size
            minWidth: '100px',  // Minimum width to maintain structure
            maxWidth: '300px',  // Maximum width to ensure it doesn't get too large
            fontSize: '13px',  // Maintain a smaller font size for better fitting
            color: 'green',  // Optional, to keep the success indication clear
            borderRadius: '10px'  } })
        setcurrentHelpedStudent({
            Name:user_name,
            Location: user_location
        });

        updateDoc(doc(firestore_db, "users", user.uid), {
            lastPickedStudentName : user_name,
            lastPickedStudentLocation : user_location
        })
        setisFlashingCards(!isFlashingCards);

    };

    const askForProfPref = () => {
        setIsAskingProfPref(true);
    }
    const [selectedQueue, setSelectedQueue] = useState('');
    const [selectedQueueTitle, setSelectedQueueTitle] = useState('');

    const handleChoosenQ = (chosenQueue, chosenQueueTitle) => {
        if (location !== '') {
            // if 400, alert failure
            // if 200, alert success using popups.
            setSelectedQueue(chosenQueue)
            setSelectedQueueTitle(chosenQueueTitle)
            toast.dismiss(); // remove the 'click the queue you want to join'

            askForProfPref()

        } else {
            toast.error("Please enter a location", {style: {
                padding: '8px 12px',  // Smaller padding to reduce overall size
                minWidth: '100px',  // Minimum width to maintain structure
                maxWidth: '300px',  // Maximum width to ensure it doesn't get too large
                fontSize: '13px',  // Maintain a smaller font size for better fitting
                color: 'red',  // Optional, to keep the success indication clear
                borderRadius: '10px'  } })
        }
    }

    const submitProfPref = () => {

        //TODO: Set the prof_name to mount
        setIsAskingProfPref(false);
        try {
            QMgrInServer(selectedQueue, location, profName) //TODO: return status code from server
            toast.success(`You have been added to ${selectedQueueTitle}`, {style: {
                padding: '8px 12px',  // Smaller padding to reduce overall size
                minWidth: '100px',  // Minimum width to maintain structure
                maxWidth: '300px',  // Maximum width to ensure it doesn't get too large
                fontSize: '13px',  // Maintain a smaller font size for better fitting
                color: 'green',  // Optional, to keep the success indication clear
                borderRadius: '10px'  } })

            setisFlashingQueues(!isFlashingQueues); //TODO: maybe we shoould cancel all
        } catch (error) { //TODO: doesn't work and it crashes.
            toast.error(error.message);
            return;
        }

    }

    const submitDisplayName = () => {

        //TODO: Set the prof_name to mount
        setIsAskingDisplayName(false);
        try {
            // QMgrInServer(selectedQueue, location, profName) //TODO: return status code from server


            //SetDoc code
            updateDoc(doc(firestore_db, "users", user.uid), {
                firstName : firstName,
                lastName : lastName
            })
            toast.success(`Your display name has been saved. Welcome again, ${formatName(firstName, lastName)}`, {style: {
                padding: '8px 12px',  // Smaller padding to reduce overall size
                minWidth: '100px',  // Minimum width to maintain structure
                maxWidth: '300px',  // Maximum width to ensure it doesn't get too large
                fontSize: '13px',  // Maintain a smaller font size for better fitting
                color: 'green',  // Optional, to keep the success indication clear
                borderRadius: '10px'  } })

        } catch (error) { //TODO: doesn't work and it crashes.
            toast.error(error.message);
            return;
        }

    }
    const cancelIsAskingDisplayName= () => {
        toast.dismiss();
        setIsAskingDisplayName(false);
        toast('👉 Click the queue you want to join', {
            autoClose: false,
            style: {
                fontSize: '13px',  // Reduce the font size as needed
                color: "blue"
            }
          });
    }
    const cancelIsAskingProf = () => {
        toast.dismiss(); // remove the 'click the queue you want to join'
        setIsAskingProfPref(false);
        toast('👉 Click the queue you want to join', {
            autoClose: false,
            style: {
                fontSize: '13px',  // Reduce the font size as needed
                color: "blue"
            }
          });
    }

  return (


        <div className = "root">
            <LoadingOverlay isLoading={isLoading} />
            {!isTeacher && <AskNameOverlay isAsking={isAskingDisplayName} firstName = {firstName} setFirstName = {setFirstName} lastName = {lastName} setLastName = {setLastName} cancel={cancelIsAskingDisplayName} submitName={submitDisplayName}/> }

            <ProfessorOverlay isAsking={isAskingProfPref} ProfName = {profName} setProfName = {setProfName} cancel={cancelIsAskingProf} submitTeacher={submitProfPref}/>
            <div className="bigger-header">
            <div className="App-header">
                {!isTeacher &&
                    <input
                        style={{
                            width: "70 px", // Adjusted to 100% to fill the container, you can set a max-width in the parent if needed
                            padding: '10px 15px', // Comfortable padding
                            fontSize: '16px', // Larger font size for better readability
                            borderRadius: '20px', // Fully rounded corners
                            border: '2px solid #ccc', // Subtle border
                            outline: 'none', // Removes the focus outline, consider accessibility implications
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' // Soft shadow for depth
                        }}
                        type="text"
                        value={location}
                        onChange={updateText}
                        placeholder="Location"
                    />
                }
                {isTeacher &&

                    <div  className={`queue-item ${currentHelpedStudent.Name != '' && currentHelpedStudent.Location != '' ? 'popping-student-card' : ''}`}>
                    <h2 className="item-name">{currentHelpedStudent.Name != '' ? currentHelpedStudent.Name : "Recently helped student"}</h2>
                    <div className="item-meta">
                        <StudentCardAttribute location={currentHelpedStudent.Location != '' ? currentHelpedStudent.Location : "No one selected yet"}/>
                    </div>
                </div>
                }
                <LogoutButton></LogoutButton>
                <Toaster/>
            </div>
            <div className="Main_q_button">
            { !isTeacher && <QButton isSelected={isFlashingQueues} revertButtonState={toggleQueuesFlashing}/>}
            { isTeacher && <DqButton  isSelected={isFlashingCards} revertButtonState={setisFlashingCards}/>}
            </div>
            </div>





            <div className="App">
                <Speciality_queue onSelectedQueue={handleChoosenQ} chooseQ={isFlashingQueues} chooseCard={isFlashingCards} onSelectedCard={handleChooseStudent}  title="PC" queue_id="queue1" leaveQueueInServer={(userId) => LeaveQueueInServer(setId, "queue1",  userId, user)} displayName={displayName()} />
                <Speciality_queue onSelectedQueue={handleChoosenQ} chooseQ={isFlashingQueues}  chooseCard={isFlashingCards} onSelectedCard={handleChooseStudent} title="Perio" queue_id="queue2" leaveQueueInServer={(userId) => LeaveQueueInServer(setId, "queue2",  userId, user)} displayName={displayName()}/>
                <Speciality_queue onSelectedQueue={handleChoosenQ} chooseQ={isFlashingQueues} chooseCard={isFlashingCards} onSelectedCard={handleChooseStudent} title="Operative" queue_id="queue3" leaveQueueInServer={(userId) => LeaveQueueInServer(setId,  "queue3", userId, user)} displayName={displayName()}/>
                <Speciality_queue onSelectedQueue={handleChoosenQ} chooseQ={isFlashingQueues}  chooseCard={isFlashingCards} onSelectedCard={handleChooseStudent} title="Prosth" queue_id="queue4" leaveQueueInServer={(userId) => LeaveQueueInServer(setId,  "queue4", userId, user)} displayName={displayName()}/>
            </div>

        </div>


  );
}


export default App;