import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../components/Container';   //background-color: #282c34;  // Dark charcoal
import Title from '../components/Title';
import Button from '../components/Button';


const positiveAffirmations = [
  "You are strong and capable.",
  "Believe in yourself.",
  "You can achieve great things.",
  "Keep pushing forward.",
  "Every day is a fresh start."
]; //Maybe replace with jokes about dentists

function Home() {
  const randomMessage = positiveAffirmations[Math.floor(Math.random() * positiveAffirmations.length)];

  const navigate = useNavigate();

  function handleButtonClick() {
    navigate('/welcome');
  }

  return (
    <Container>
      <Title>Welcome! {randomMessage}</Title>
      <Button onClick={handleButtonClick}>Get on the Q</Button>
    </Container>
  );
}


export default Home;

