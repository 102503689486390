import styled from 'styled-components';

const Button = styled.button`
  padding: 12px 25px;
  font-size: 18px;
  color: #282c34;
  background-color: #F8F8F8;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  width: 160px;

  &:hover {
    background-color: #EFEFEF;  // Slightly darker on hover
  }
`;

export default Button;