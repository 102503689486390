import React from 'react';
import './AskNameOverlay.css';
import styled from 'styled-components';

const Button = styled.button`
   appearance: none;
    border: .2em solid #fff;
    background: rgb(40, 44, 52, 0.8);
    padding: .85em 1.5em;
    color: #fff;
    border-radius: 2em;
    width: 100px;
    height: 40px;
    margin: 0px 30px;
    transition: 0.2s;
    &:hover, &:focus, &:active {
        background: #fff;
        color: #000;
    }
        `;

const AskNameOverlay = ({ isAsking, firstName, lastName, setFirstName, setLastName, cancel, submitName }) => {
    if (!isAsking) return null;
    const updateFirstName = (event) => {
        setFirstName(event.target.value);
    };
    const updateLastName = (event) => {
        setLastName(event.target.value);
    };
    return (
        <div className="askName-overlay">
            <div className="text">
                Enter Your First and Last Name
            </div>
            <div className="text-smaller">
                Professors like to see your name instead of email
            </div>

            <input
                style={{
                    width: "70 px", // Adjusted to 100% to fill the container, you can set a max-width in the parent if needed
                    padding: '10px 15px', // Comfortable padding
                    fontSize: '16px', // Larger font size for better readability
                    borderRadius: '20px', // Fully rounded corners
                    border: '2px solid #ccc', // Subtle border
                    outline: 'none', // Removes the focus outline, consider accessibility implications
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' // Soft shadow for depth
                }}
                type="text"
                value={firstName}
                onChange={updateFirstName}
                placeholder="First Name"
            />
            <div style={{height:10}}></div>
             <input
                style={{
                    width: "70 px", // Adjusted to 100% to fill the container, you can set a max-width in the parent if needed
                    padding: '10px 15px', // Comfortable padding
                    fontSize: '16px', // Larger font size for better readability
                    borderRadius: '20px', // Fully rounded corners
                    border: '2px solid #ccc', // Subtle border
                    outline: 'none', // Removes the focus outline, consider accessibility implications
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' // Soft shadow for depth
                }}
                type="text"
                value={lastName}
                onChange={updateLastName}
                placeholder="Last Name"
            />
            <div class="buttons-section">
                <Button onClick={submitName}>Continue</Button>
                <Button onClick={cancel}>Cancel</Button>
            </div>


        </div>
    );
};

export default AskNameOverlay;
