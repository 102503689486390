// Welcome.js
// import React from 'react';
// import SignUp from '../auth/register';
// import SignIn from '../auth/login';
// import Container from '../components/Container';   //background-color: #282c34;  // Dark charcoal


// const Welcome = () => {
//   return (
//     // <div>
//         <Container>
//       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'space-between'}}>
//       <SignUp />
//       <SignIn />
//       </div>
//         </Container>


//   );
// };

// export default Welcome;

import React from 'react';
import SignUp from '../auth/register';
import SignIn from '../auth/login';
import { toast, Toaster } from 'react-hot-toast';
import './welcome.css';

import styled from 'styled-components';

const Customized_container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;  // Dark charcoal
  color: #F8F8F8;  // Off-white text for readability
  font-family: 'Helvetica Neue', sans-serif;
  padding:120px;
`;
const Welcome = () => {
  return (
    <Customized_container>
      <div class='welcome-content'>
        <SignIn />
        <SignUp />
        <Toaster></Toaster>
      </div>
    </Customized_container>
  );
};

export default Welcome;
