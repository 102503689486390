// Function to get the current user's token
import { API_URL } from '../setup_env';


  // Example function to make an authenticated request to the backend
const MakeAuthenticatedRequest = async (endpoint, token, options = {}) => {
    const { body, method = 'GET', headers: customHeaders = {} } = options;
    if (!token) {
        throw new Error('User not authenticated');
    }

    const headers = {
        'Authorization': `Bearer ${token}`,
        ...customHeaders
    };
    const requestOptions =  {
        method: method.toUpperCase(), // Ensure method is in uppercase
        headers: headers,
        body: body ? JSON.stringify(body) : undefined // TODO: potential problem with no body, maybe introduce flow control on undefined
    };

    console.log("API_URL:", API_URL)
    const url = API_URL + '/' + endpoint
    const response = await fetch(url, requestOptions);
    if (response.status >= 300) {
        throw new Error("Internal Error. Please contact customer support. Reference: Non-successful status code "+ response.status + " on endpoint: " + endpoint)
    }
    return response;

};

export default MakeAuthenticatedRequest;