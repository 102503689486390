import { toast } from 'react-hot-toast';
import Button from './Button';

const QButton = (props) => {
  const isSelected= props.isSelected;
  const revertButtonState =  props.revertButtonState;

  const handleChange = () => {
    //DONT MOVE: These two are coupled
    revertButtonState(!isSelected);
    //DONT MOVE: These two function calls are coupled

    if (!isSelected) {
      toast('👉 Click the queue you want to join', {
        autoClose: false,
        style: {
            fontSize: '13px',  // Reduce the font size as needed
            color: "blue"
        }
      });
    }
    else {
        console.log("Calling above the toast.dimss in Qbutton");
        toast.dismiss();
    }

  };

  return (
    <div >
      <Button onClick={handleChange}>{isSelected ? "Cancel":"Get on the Q"}</Button>
    </div>

  );
};

export default QButton;
