

function StudentCardAttribute(props) {
    return (
        <div className="StudentCardAttribute">
            <p className="item-location">{props.location}</p>
        </div>
    );
}

export default StudentCardAttribute;