import React, { useState, useEffect } from 'react';
import "./SpecialtyQueue.css"
import StudentCardPref from './pref_card';
import StudentCardAttribute from './location_card';
import io from 'socket.io-client';
import {GetIsTeacher, InitUserStateListener} from '../auth/login';
import '../auth/login';
import MakeAuthenticatedRequest from '../auth/token'
import { API_URL } from '../setup_env';
import { FaTrashAlt } from 'react-icons/fa'; // Importing an icon from react-icons
import { IoMdCloseCircleOutline } from "react-icons/io";


function SpecialityQueue({ title, queue_id, leaveQueueInServer, chooseQ, onSelectedQueue, chooseCard, onSelectedCard, displayName}) {

    const [queue, setQueue] = useState(null);
    const [user, setUser] = useState(null);
    InitUserStateListener(setUser);
    useEffect(() => {
        if (!user) return;

        user.getIdToken().then(userToken => {  // Assuming getIdToken returns a Promise
            MakeAuthenticatedRequest(`queue/${queue_id}`, userToken, {
                method: 'GET'
            })
            .then(response => response.json())
            .then(data => {
                setQueue(data);
            })
            .catch(error => {
                console.error('Error:', error); // Consider more user-friendly error handling
            });
        });
    }, [user]);
    useEffect(
        () => {
            const socket = io(API_URL,
                {withCredentials: false,
                    transports: ['websocket'],
                }
                );
            //TODO: make it change to make for queue_id
            socket.on(`item_enqueued_${queue_id}`, (newItem) => {
                setQueue(queue => [...queue, newItem]);
                console.log("called socket add stuff");
            });

            //TODO: make it change to make for queue_id
            socket.on(`item_removed_${queue_id}`, (newItem) => {
                setQueue(queue => queue.filter(item => item.id !== newItem.id));
                console.log("called socket delete stuff");
            });
            return () => {
                socket.off(`item_enqueued_${queue_id}`);
                socket.off(`item_removed_${queue_id}`);
            };
        },[]);


    const remove_me = (user_id) => {
        console.log("user_id: ", user_id)
        leaveQueueInServer(user_id)
    }
    const changeSelectedCard = (user_id, user_name, user_location) => {
        if (chooseCard) {
            //call the function that triggers the server
            remove_me(user_id)
            console.log(user_id, "removed");
            onSelectedCard(user_name, user_location);
        }
    }
    const changeSelectedQ = () => {
        if (chooseQ) {
            //call the function that triggers the server
            onSelectedQueue(queue_id, title);
        }
    }
    const truncateEmail = (nameOrEmail) => {
        const domainToRemove = "@tufts.edu"; //TODO: make more dynamic for other schools
        if (nameOrEmail.includes(domainToRemove)) {
            return nameOrEmail.replace(domainToRemove, "")
        } else {
            return nameOrEmail; 
        }
    }
    return (
        <div onClick={changeSelectedQ}  className={`queue-container-bigger ${chooseQ ? 'popping-queue' : '' }`}>
            <h1 className="queue-title">{title}</h1>

        <div className={'queue-container'}>
            <div className="items-container">
                {queue && queue.map(item => (
                    <div  onClick={ () => changeSelectedCard(item.id, item.name, item.location)} key={item.id} className={`queue-item ${chooseCard ? 'popping-student-card' : '' }`}>
                        {displayName == item.name &&
                        <div style={{display: "flex", justifyContent: "space-between", alignItems:'center' }}>
                        <div></div>
                        <h2 className="item-name">{truncateEmail(item.name)}</h2>
                        <button className='x-button' onClick={() => remove_me(item.id)}>
                            <IoMdCloseCircleOutline color={'black'} size={15}/>
                                </button>
                        </div>
                        }
                               {displayName != item.name  && <h2 className="item-name">{item.name}</h2>}


                        <div className="item-meta">
                            <StudentCardAttribute location={item.location}  />
                            {item.pref_prof != '' && <StudentCardPref pref={item.pref_prof}  />}

                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
}

export default SpecialityQueue;
//{InitUserStateListener() == item.name &&}