import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import App_wrapper from './App_wrapper';

import reportWebVitals from './reportWebVitals';
import Welcome from './screens/Welcome';
import Home from './screens/Home';
import PrivateRoute from './auth/privateRoute'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Router>
        <Routes>

            <Route exact path="/" element={<Home/>} > </Route>
            <Route exact path="/welcome" element={<Welcome/>} > </Route>
            <Route path="/app" element={<App/>} ></Route>
            <Route path="/app_wrapper" element={<App_wrapper/>} ></Route>


            {/* <PrivateRoute path="/app" component={<App/>} /> */}
        </Routes>
      </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
