import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import Button from './Button';

const DqButton = (props) => {
    const isSelected= props.isSelected;
    const [selectedValue, setSelectedValue] = useState('');
    const revertButtonState =  props.revertButtonState;

    // const handleChange = (event) => {
    //   let selectedQueue = event.target.value;
    //   setSelectedValue(selectedQueue);
    //   props.dQInServer(selectedQueue); //TODO: error handling
    //   toast.success(`${selectedQueue} was dQued`)

    //   setSelectedValue("dqueue");


    // };
    const handleChange = () => {
        //DONT MOVE: These two are coupled
        revertButtonState(!isSelected);
        //DONT MOVE: These two function calls are coupled

        if (!isSelected) {
          toast('👉 Choose the student you want to help', {
            autoClose: 9000,
            style: {
                fontSize: '13px',  // Reduce the font size as needed
                color: "blue"
            }
          });

        }
        else {
            console.log("dismiss above dq");
            toast.dismiss();
        }

      };


    return (
        <div >
          <Button onClick={handleChange}>{isSelected ? "Cancel":"Help a Q"}</Button>
        </div>

      );
  };


  export default DqButton;