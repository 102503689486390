import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;  // Dark charcoal
  color: #F8F8F8;  // Off-white text for readability
  font-family: 'Helvetica Neue', sans-serif;
  overflow: hidden;
`;
export default Container;