import React from 'react';
import './App_wrapper.css'; // Import your CSS file for styling
import App from './App';

function App_wrapper() {
  return (
    <div className="app_wrapper">
      <App/>
    </div>
  );
}

export default App_wrapper;