// src/components/SignUp.js
import React, { useState, useEffect} from 'react';
import { auth } from './firebaseConfig';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { InitUserStateListener } from './login';
import './authstyles.css';
import CoalTitle from '../components/CoalTitle'
import { toast, Toaster } from 'react-hot-toast';
import './register.css';
import {firestore_db} from './firebaseConfig';
import { doc, setDoc } from "firebase/firestore";



const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isTeacher, setIsTeacher] = useState('');
  const [waitingForVerification, setWaitingForVerification] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  InitUserStateListener(setUser);

  const POLLING_INTERVAL = 5000; // Polling interval in milliseconds

  const startPollingForVerification = (user, isTeacher) => {

    const poll = setInterval(() => {
      user.reload().then(() => {
        if (user && user.emailVerified) {

            clearInterval(poll);
            setWaitingForVerification(false);
            navigate('/app');

          }
      }).catch(error => {
        toast.error("Error reloading user: ", error);
      });
    }, POLLING_INTERVAL);
    return () => clearInterval(poll); // Cleanup on component unmount

  };


  const labelStyle = {
    color: 'black',
  };

  const handleSignUp = async (e) => {
    e.preventDefault(); // Prevent the form from submitting traditionally

    // Clear any previous toasts
    toast.dismiss();

    // Validation checks
    let hasError = false;

    if (isTeacher === '') {
        toast.error("'Are you a teacher?' is required.");
        hasError = true; // Mark that there was an error
    }
    if (!email) {
        toast.error("Email is required.");
        hasError = true; // Mark that there was an error
    }
    if (!password) {
        toast.error("Password is required.");
        hasError = true; // Mark that there was an error
    }
    if (email && !email.endsWith('.edu')) {
        toast.error("Please use an educational email address.");
        hasError = true; // Mark that there was an error
    }

    // If there was any error, return early
    if (hasError) return;




    //only accept educational emails
    if (!email.endsWith('.edu')) {
        toast.error('Please use an educational email address');
    } else { //TODO: uncomment for educational
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const stopMe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    // Unsubscribe from further auth state changes
                    stopMe();
                    user.getIdToken(true) //force refresh token before sending email verification
                    //This doesn't continue:
                    sendEmailVerification(user).then(() => {
                        toast.success("Please check your email to verify registration!") //TODO: toast it
                    });
                    setWaitingForVerification(true);
                    startPollingForVerification(user, isTeacher);
                    setDoc(doc(firestore_db, "users", user.uid), {
                      email: user.email,
                      isTeacher: isTeacher,
                      lastPickedStudentName : '',
                      lastPickedStudentLocation : '',
                      firstName: '',
                      lastName: ''
                    }).then()

                }
            });

            //TODO: revert sign up if verification fails
            // OR verify email first
        } catch (error) {
            toast.error(error.message);
        }
    }

  };
  const handleInvalid = (e) => {
    e.preventDefault();
  }



  return (
    <div className="container flashing-border">
              <CoalTitle>Sign Up</CoalTitle>
      <form className="form" onSubmit={handleSignUp}  >
        <label className="label">Email</label>
        <input className="input"
               type="email"
               value={email}
               onChange={e => setEmail(e.target.value)}
                />

        <label className="label">Password</label>
        <input className="input"
               type={showPassword ? "text" : "password"}
               value={password}
               onChange={e => setPassword(e.target.value)}
                />
        <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer', fontSize: '14px', color: "black",     userSelect: 'none' }}>
          {showPassword ? "hide" : "show"} password
        </span>
        <label className="label">Are you a teacher?</label>
        <div>
          <input type="radio"
                 id="teacher-yes"
                 name="isTeacher"
                 value="yes"
                 className="radio-button"
                 checked={isTeacher === 'yes'}
                 onChange={e => setIsTeacher(e.target.value)}
                  />
          <label htmlFor="teacher-yes" style={labelStyle}>Yes</label>
        </div>
        <div>
          <input type="radio"
                 id="teacher-no"
                 name="isTeacher"
                 value="no"
                 className="radio-button"
                 checked={isTeacher === 'no'}
                 onChange={e => setIsTeacher(e.target.value)}
                  />
          <label htmlFor="teacher-no" style={labelStyle}>No</label>
        </div>

        {error && <div className="error-modal">{error}</div>}
        <button className="button">Sign up</button>
      </form>
        </div>
  );
};
//TODO: make it another screen for verification

export default SignUp;
