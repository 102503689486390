// // src/components/SignIn.js
import React, { useState, useEffect } from 'react';
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './authstyles.css';
import CoalTitle from '../components/CoalTitle'
import { toast, Toaster } from 'react-hot-toast';


//Checks if user is signed in or out
export function InitUserStateListener(setUser) {
  const navigate = useNavigate();
    // return auth.currentUser.email;  //TODO: get the logged in name instead of email
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
        navigate('/welcome');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
}
export function GetIsTeacher() {
    return false;  //TODO: change on authentication if teacher.
}

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      if (user.emailVerified) {
        navigate('/app');
      } else {
        toast.error('Please verify your email address.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleInvalid = (e) => {
    e.preventDefault();
    toast.error("Email or password isn't formatted");
  }

  return (
    <div className="container flashing-border">
      <CoalTitle>Sign In</CoalTitle>
      <form className="form" onSubmit={handleSignIn}>
        <label className="label">Email</label>
        <input className="input"
               type="email"
               value={email}
               onChange={e => setEmail(e.target.value)}
               onInvalid={handleInvalid}  // Handle invalid event
               required />

        <label className="label">Password</label>
        <input className="input"
               type={showPassword ? "text" : "password"}
               value={password}
               onChange={e => setPassword(e.target.value)}
               onInvalid={handleInvalid}  // Handle invalid event
               required />
        <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer', fontSize: '14px', color: "black",     userSelect: 'none' }}>
          {showPassword ? "hide" : "show"} password
        </span>


        {/* {error && <div className="error-modal">{error}</div>} */}


        <button className="button" type="submit">Sign in</button>
      </form>
      <span onClick={() => toast.error("Please contact adminstration.")}style={{ cursor: 'pointer',  fontSize: '14px', marginTop: '10px', color: "black",     userSelect: 'none' }}>
        Forgot password?
      </span>


    </div>
  );
};

export default SignIn;
