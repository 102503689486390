// src/components/LogoutButton.js
import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';




const LogoutButton = () => {
  const navigate = useNavigate();

  function handleButtonClick() {
    navigate('/welcome');
  }

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('User signed out.');
      handleButtonClick();

      // Optionally redirect to login page or perform other actions upon successful logout
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });

  };

  return (
    // <button onClick={handleLogout}>Logout</button>
      <button onClick={handleLogout} title="Logout" style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>

        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: '24px', color: '#555' }} />
      </button>

  );
};

export default LogoutButton;
